import { Link, Route, Routes } from 'react-router-dom';
import './reset.css'
import './App.css';
import Album from './components/Albums/Album/Album';
import Albums from './components/Albums/Albums';
import Error404 from './components/Errors/Error404';
import Home from './components/Home/Home';
import Footer from './components/Main/Footer/Footer';
import Header from './components/Main/Header/Header';
import Contacts from './components/Contacts/Contacts';
import Services from './components/Services/Services';
import Products from './components/Products/Products';
import Product from './components/Product/Product';
import Credits from './components/Credits/Credits';
import { useState, useEffect } from 'react';
import CookieConsent, {Cookies} from 'react-cookie-consent';
import PrivacyStatement from './components/PrivacyStatements/PrivacyStatement';
import CookieStatement from './components/CookieStatements/CookieStatement';


function App() {

  const cookie = Cookies.get('CookieConsent')
  const [consent, setConsent] = useState(Cookies.get('CookieConsent') === 'true');

  window.dataLayer = window.dataLayer || [];
  function gtag() { window.dataLayer.push(arguments); }

  useEffect(() => {
      if (consent) {
        gtag('consent', 'update', {
          ad_user_data: 'granted',
          ad_personalization: 'granted',
          ad_storage: 'granted',
          analytics_storage: 'granted'
        });
      }
      else {
        gtag('consent', 'default', {
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          ad_storage: 'denied',
          analytics_storage: 'denied',
          wait_for_update: 500,
        });
        gtag('js', new Date());
        // gtag('config', 'Google tag ID');
      }
  }, [consent, cookie])
  
  const handleAccept = () => {
    Cookies.set('CookieConsent', 'true', {expires: 365, sameSite: 'strict' });
    setConsent(true)
  }

  const handleDecline = () => {
    Cookies.set('CookieConsent', 'false', {expires: 365, sameSite: 'strict' });
    setConsent(false)
  }
  // ===================================================================
  return (
    <div className='body'>
        <Header></Header>
          <div className={'main'}>
            <Routes>
              <Route path='/' element={<Home />}/>
              <Route path='/projects' element={<Albums />}/>
              <Route path='/album/:id/' element={<Album />}/> 
              <Route path='/products' element={<Products />}/>
              <Route path='product/:id/' element={<Product/>}/>
              <Route path='/services' element={<Services />}/>
              <Route path='/contacts' element={<Contacts />}/>
              <Route path='/credits' element={<Credits />}/>
              <Route path='/privacy-statement' element={<PrivacyStatement />}/>
              <Route path='/cookie-statement' element={<CookieStatement/>}/>
              <Route path='*' element={<Error404 />}/>
            </Routes>
          </div>
        <Footer></Footer>

        {cookie ?
          null
            :
          <CookieConsent
            sameSite='Strict'
            location="bottom"
            flipButtons
            style={{padding: '1em', marginBottom: '1em'}}
            buttonText="I Agree"
            onAccept={handleAccept}
            enableDeclineButton
            declineButtonText='Decline'
            onDecline={handleDecline}
          >
            By clicking on the "I Agree" button, you consent to the use of cookies to enhance your browsing experience. 
            For more information on how we use and protect your data, please visit our Privacy Statement. Click here to view our <Link to={'/privacy-statement'}> Privacy Policy</Link> and here to view our <Link to={'/cookie-statement'}> Cookie Policy</Link>.
          </CookieConsent>
        }

    </div>
  );
}

export default App;
